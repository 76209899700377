import React, {useContext, useEffect, useState} from "react"
import _ from "lodash";
import moment from "moment"
import {AppSync} from "../../libraries/appsync";
import AuthContext from "../Context/AuthContext"
import style from "./accountsummary.module.css";
import classNames from "classnames";

export default () => {
  const [loading, setLoading] = useState(true)
  const [account, setAccount] = useState({})
  const [apiError, setApiError] = useState(null)
  const {session, personalCode} = useContext(AuthContext)

  useEffect(() => {
    if (_.isEmpty(session)) {
      setApiError(<span>ご注文者情報はありません。</span>)
      return
    }

    setApiError(null)
    setLoading(true)

    getAccountFull(personalCode)
    .then(res => {
      setLoading(false)
      setAccount(_.get(res.data, "data.getTapirsUserFull"))
    })
    .catch(err => {
      console.error(err)
      setLoading(false)
      setApiError(err)
    })
  }, [personalCode])

  return (
    <div className={classNames({loading: loading}, "container", "bg-white", "inner")}>
      {(loading === false && apiError) && <p className="update error">エラー：{apiError}</p>}

      <h1 style={{
        margin: "0 auto 16px",
        maxWidth: "520px",
        color: "var(--header-color)",
        fontSize: "19px",
        fontWeight: "bold",
        textAlign: "left",
      }}>ご注文者情報の表示</h1>

      <div className={style.fieldWrapper}>
        <div className="col2">
          <div className="field">
            <span className="form-label">氏名</span>
            <p>{account.name1} {account.name2}</p>
          </div>
          <div className="field">
            <span className="form-label">氏名カナ</span>
            <p>{account.name1_kkn} {account.name2_kkn}</p>
          </div>
        </div>

        <div className="col2">
          <div className="field">
            <span className="form-label">生年月日</span>
            <p>{moment(account.birth_ymd).format("YYYY年MM月DD日")}</p>
          </div>
          <div className="field">
            <span className="form-label">性別</span>
            <p>{genderToString(account.gender)}</p>
          </div>
        </div>

          <div className="field">
            <span className="form-label">郵便番号</span>
            <p>{account.zip}</p>
          </div>
          <div className="field">
            <span className="form-label">都道府県</span>
            <p>{account.prefecture}</p>
          </div>
          <div className="field">
            <span className="form-label">住所1 市区町村番地</span>
            <p>{account.address1}</p>
          </div>
          <div className="field">
            <span className="form-label">住所2 建物名</span>
            <p>{account.address2}</p>
          </div>
          <div className="field">
            <span className="form-label">電話番号</span>
            <p>{account.tel1}</p>
            <br/>
            <p className="comment">
              ご注文済みの商品の配送先の変更は、<a href={process.env.MODD_CART_URL + "member/OrderList.aspx?ccode=" + process.env.MODD_CART_ID} target="_blank" rel="noreferrer" className="underline">［注文履歴］</a>より変更が可能です。
            </p>
          </div>
      </div>
    </div>
  )
}

export const genderToString = id => {
  switch (id) {
    case 1: return "男性"
    case 2: return "女性"
    case 3: return "その他"
    default: return ""
  }
}

export const getAccountFull = personalCode => AppSync.post("/", {
  query: `
    query getTapirsUserFull {
      getTapirsUserFull(personalCode: "${personalCode}") {
        personal_c
        nickname
        name1
        name2
        name1_kkn
        name2_kkn
        birth_ymd
        gender
        member_type
        country
        zip
        prefecture
        address1
        address2
        tel1
      }
    }
  `
})
